@import '../../../styles/containers/editionmanager/core/_variables.scss';

.manager-action {
  background: $edm-coolgrey;
  border: 1px solid $edm-lightgrey;
  border-radius: 2px;
  color: $edm-white;
  cursor: pointer;
  font-size: 11pt;
  font-weight: 700;
  min-width: 120px;
  outline: none;
  padding: 10px 20px;
  margin-right: 10px;

  &:hover {
    background: $edm-mintgreen;
    color: $edm-black;
  }

  // Extracted from app/styles/containers/editionmanager/editiondetails/_header.scss
  &:disabled {
    background: $edm-coolgrey;
    border: $edm-coolgrey2;
    color: $edm-coolgrey2;
    cursor: not-allowed;
    opacity: .5;


    &:hover {
      background: $edm-coolgrey;
      border: $edm-coolgrey2;
      color: $edm-coolgrey2;
      cursor: not-allowed;
    }
  }

  &:last-child {
      margin-right: 0px;
  }
}

.manager-action--selected {
  background: #50e9ba;
  color: #000;
}
