@import '../../../styles/containers/editionmanager/core/_variables.scss';

.manager-header {
  display: block;
  float: none;
  height: 107px;
  margin: 0 auto 10px;
  width: 1170px;
}

// Extracted from app/styles/containers/editionmanager/editiondetails/_header.scss
.manager-header__title {
  display: block;
  // TODO Re-design to remove this float
  float: left;
  font-size: 14pt;
  font-weight: 300;
}

.manager-header__actions {
    display: block;
    float: right;
    margin-top: -10px;
}

// Extracted from app/styles/containers/editionmanager/editiondetails/_header.scss
.manager-header__colorbar {
  border-radius: 4px;
  display: block;
  float: left;
  height: 4px;
  margin-top: 10px;
  width: 100%;
}

// Extracted from app/styles/containers/editionmanager/editiondetails/_editionmanager-editiondetails-header-colorbars.scss
.manager-header__colorbar--greenblue {
  animation-duration: 1s;
  animation-name: colorbar-animate;
  background: $edm-colorbar-checkedouttoyou;
}

.manager-header__colorbar--redblue {
  animation-duration: 1s;
  animation-name: colorbar-animate;
  background: $edm-colorbar-checkedouttoother;
}

.manager-header__colorbar--lightblueblue {
  animation-duration: 1s;
  animation-name: colorbar-animate;
  background: $edm-colorbar-open;
}

.manager-header__colorbar--goldblue {
  animation-duration: 1s;
  animation-name: colorbar-animate;
  background: $edm-colorbar-archived;
}
