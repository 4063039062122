@import '../../../styles/containers/editionmanager/core/_variables.scss';
@import './manager-icons';

// Extracted from app/styles/containers/editionmanager/core/_animaions.scss
@keyframes pulse-loading {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes hidesearch {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

// TODO Overlaps w/ manager-section, look into how to avoid
// mixins but still share rules, perhaps CSS vars?
.manager-row-listing {
  .manager-table-loader {
    animation: pulse-loading .5s infinite;
    display: none;
    height: 0;
    margin-bottom: -100px;
    opacity: 0;
    overflow: visible;
    position: relative;
    text-align: center;
  }

  // Extracted from `.search-results table` in _editionsearch-results.scss
  .manager-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .manager-table--searching {
    animation: hidesearch .25s;
    opacity: 0;
  }

  .manager-table-loader--searching {
    display: block;
  }

  // Extracted from .search-load-more in _editionsearch-results.scss
  .manager-table-pager {
    background: $edm-darkgrey;
    display: block;
    padding: 5px 0;
    text-align: center;
    width: 99.8%;
  }


  // Extracted from `.search-results table tr th` in _ediitonsearch_results.scss
  .manager-table-column {
    cursor: default;
    font-size: 11px;
    height: 20px;
    overflow: hidden;
    padding: 20px 0;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    // min-width: 75px;
  }

  .manager-table-column--sortable {
    cursor: pointer;
  }

  // Extracted from normalize.css
  .manage-table-column__name {
    font-weight: 700; // TODO use variable
  }

  .manager-table-column__name--sortable {
    display: block;
    float: left;
  }

  .manager-table-column__sort-icon {
    display: block;
    background-color: $edm-lightgrey;
    float: left;
    margin-left: 10px;
    &:hover {
      background-color: $edm-white;
    }
  }

  .manager-table-column__sort-icon--sorting {
    background-color: $edm-white;
  }

  .manager-table-column__sort-icon--asc {
    margin-top: 3px;
    transform: rotate(180deg);
  }

  .manager-table-column__sort-icon--desc {
    margin-top: 2px;
  }

  .manager-table-row {
    border-left: 1px solid $edm-darkgrey;
    border-right: 1px solid $edm-darkgrey;
    &:hover {
      border-left: 1px solid $edm-mintgreen;
      border-right: 1px solid $edm-mintgreen;
      border-bottom: 1px solid $edm-mintgreen;
      border-top: 1px solid $edm-mintgreen;
    }
    &:nth-child(2n) {
      background: $edm-grey;
    }
  }

  .manager-table-data {
    border-bottom: 1px solid $edm-darkgrey;
    border-top: 1px solid $edm-darkgrey;
    cursor: default;
    font-size: 14px;
    height: 20px;
    overflow: hidden;
    padding: 20px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #b9bbc0;
    margin: 0;

    &:hover {
      padding: 20px 0;
    }
  }
}


.manager-table-data__abort{
  border:1px solid #666;
  border-radius:5px;
  padding:5px 10px;
  background-color:#333;
  
  &:hover, &:focus{
    color:#e1e1e1;
    border-color:#e1e1e1;
    background-color:#555;
  }
}