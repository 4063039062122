@import '../../../styles/containers/editionmanager/core/_variables.scss';

.manager-breadcrumb {
    color: $edm-lightgrey;
    cursor: pointer;
    display: block;
    float: left;
    margin-bottom: 40px;
    width: 100%;

    span {
      font-size: 11pt;
    }

    .home {

      &:hover {
        color: $edm-white;
        text-decoration: underline;
      }
    }
  }

