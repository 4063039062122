@import '../../../styles/containers/editionmanager/core/_variables';

.manager-search {
  // Extracted from app/styles/containers/editionmanager/core/_core.scss
  height: 41px;
  padding: 70px 0;
}

// Extracted from app/styles/containers/editionmanager/homepage/_editionsearch-controls.scss
.manager-search__query {
  border-bottom: 1px solid $edm-lightgrey;
  float: left;
}

// Extracted from app/styles/containers/editionmanager/homepage/_editionsearch-controls.scss
// Because there's an attribute selector on <input> somewhere, which have equal specificity
// to classes, must use ID here to override JFC
#manager-search__input {
// TODO localize SVG refs
  background: url('../../../images/editionmanager/search-icon.svg') no-repeat;
  background-position: 7px 7px;
  border: 0;

  color: $edm-white;
  display: inline-block;
  float: left;
  font-size: 18px;
  outline: 0;
  padding: 8px 40px;
  width: 500px;
}

// Extracted from app/styles/containers/editionmanager/homepage/_editionsearch-controls.scss
.manager-search__reset {
  // TODO localize SVG refs
  background: url('../../../images/editionmanager/remove-icon.svg') no-repeat;
  background-position: 7px 14px;
  border: 0;
  color: $edm-white;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 18px;
  height: 41px;
  outline: none;
  padding: 12px 0;
  width: 32px;
}

.manager-search__actions {
  float: right;
  height: 41px;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.manager-search__actions-label {
  font-size: 16px;
  margin-right:10px;
}

// TODO should be able to delete
/**
.manager-search__action {
  margin-right: 10px;
}
**/
